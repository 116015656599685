@charset "utf-8";

// Import a Google Font
//@import url('https://fonts.googleapis.com/css?family=Nunito:400,700');
@import url('https://fonts.googleapis.com/css?family=Roboto:400,700');

// Set your brand colors
$purple: #8a4d76;
$pink: #fa7c91;// #103f9f;
$brown: #757763;
$beige-light: #d0d1cd;
$beige-lighter: #eff0eb;

// Update Bulma's global variables
$family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
//$grey-dark: $brown;
//$grey-light: $beige-light;
//$primary: $purple;
$link: $pink;
$input-focus-border-color: $pink;
$input-focus-box-shadow-size: 0;
$input-radius: 16px;
//$widescreen-enabled: false;
//$fullhd-enabled: false;

// Update some of Bulma's component variables
//$body-background-color: $beige-lighter;
//$control-border-width: 2px;
//$input-border-color: transparent;
//$input-shadow: none;

// Import only what you need from Bulma
@import '../../node_modules/bulma/sass/utilities/_all.sass';
@import '../../node_modules/bulma/sass/base/_all.sass';
@import '../../node_modules/bulma/sass/elements/_all.sass';
@import '../../node_modules/bulma/sass/components/_all.sass';
@import '../../node_modules/bulma/sass/layout/_all.sass';
@import '../../node_modules/bulma/sass/form/_all.sass';
@import '../../node_modules/bulma/sass/grid/_all.sass';
@import '../../node_modules/bulma-extensions/bulma-pageloader/dist/css/bulma-pageloader.sass';
