* {
  /* font-family: "Noto Sans"; */
  color: #242424;
}
#App {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}
#appWrapper {
  flex: 1;
}
.pageloader {
  background: #d8eff4;
}
a {
  color: #f15025;
}
.item-title {
  height: 24px;
}

.wrapper-slider-img span {
  font-family: "Noto Sans";
  color: #242424;
  font-size: 0.6em;
  vertical-align: middle;
  margin-left: 2px;
  font-weight: 300;
}

*:focus {
  outline: none !important;
}

textarea:focus {
  box-shadow: none !important;
  border-color: rgb(137, 137, 137) !important;
}

::placeholder {
  color: #aaa !important;
  opacity: 1;
}

.clear {
  clear: both;
}

hr {
  margin: 40px 0;
}
h2 {
  font-size: 2.18em;
  font-family: "Lato", sans-serif;
  text-align: center;
  width: 100%;
  color: #033a44;
  font-weight: 600;
}
h3,
.title.is-3 {
  font-family: "Lato", sans-serif;
  font-size: 1.75em;
  text-align: center;
  font-weight: 500;
  letter-spacing: 0.01em;
  color: #f15025;
}
h4 {
  text-transform: uppercase;
  color: #033a44;
  text-align: center;
}

h5 {
  color: #033a44;
  text-align: center;
}

h6 {
  color: #4a4a4a;
  font-weight: 700;
  text-align: center;
}
.container {
  margin: 0 auto;
  padding: 0 20px;
}

.mainContent {
  margin-top: 0;
}

.max-container {
  max-width: 1600px;
  margin: auto;
}

/* Navbar */

.bluebgcolor {
  background-color: #d8eff4;
}

.orangecolor {
  color: #f15025;
}

.greencolor {
  color: #033a44;
}

.graybgcolor {
  background-color: #e7ecef;
}
.navbar {
  z-index: auto;
}

.navbar-burger.burger {
  border: none;
  background: none;
}

.navbar-menu:not(.is-active) .navbar-link:not(.is-arrowless) {
  line-height: 80px;
}
.navbar-link:not(.is-arrowless)::after {
  bottom: 30px;
  border-color: #f15025;
}

.navbar-link,
.navbar-item,
.navbar-dropdown > a {
  color: #033a44;
}

.navbar-dropdown .navbar-item > img {
  margin-right: 10px;
  float: left;
  max-width: 28px;
  max-height: 28px;
}

a.navbar-item:hover,
a.navbar-item.active,
a.navbar-link:hover,
a.navbar-link.active,
span.navbar-link:hover,
span.navbar-link.active,
.navbar-dropdown > a:hover {
  color: #f15025 !important;
  background-color: none;
}

.navbar-burger > i {
  font-size: 1.3em;
}

#navbar-collapse-button {
  box-shadow: none;
}

#navbar-collapse-button .navbar-link {
  padding-right: 0.75em;
  justify-content: center;
}

#navbar-collapse-button .navbar-link::after {
  top: auto;
  right: auto;
  margin-top: 1em;
}
.navbar-brand {
  align-items: center;
}
.navbar-brand .navbar-burger.burger > span {
  height: 2px;
}
.navbar-item > .i-search {
  cursor: pointer;
}
/* Slider */
#hero-title-h1 {
  color: #033a44;
  font-size: 3.1em;
  font-weight: 700;
  line-height: 1.3em;
  margin-bottom: 2.5rem;
}

.button.is-primary.is-large {
  box-sizing: border-box;
  border: 2px solid #f15025;
  background: transparent;
  border-radius: 30.5px;
  font-size: 1.3em;
  color: #f15025;
  text-transform: uppercase;
  padding-left: 2em;
  padding-right: 2em;
}

#herotitlealign {
  margin-top: 5rem;
}
.main-show .wrapper-slider-img > p,
.main-show .wrapper-slider-img > hr {
  position: absolute;
}

.wrapper-slider-img .hint {
  padding: 0.2em;
  font-size: 1.5em;
  border-radius: 50%;
  color: #f15025;
  position: absolute;
}

.checkMark {
  /* animation-duration: 2s; */
  position: absolute;
  z-index: 1;
}
.checkmarksnslides {
  position: relative;
  z-index: 1;
}

.wrapper-slider-img hr {
  margin: 0 0;
  width: 5em;
  height: 1px;
  background-color: #979797;
}

.wrapper-slider-img .subtitle.is-5 {
  text-align: left;
  margin-top: 5px;
}
.wrapper-slider-img .subtitle.is-3 {
  text-align: left;
  color: #033a44;
  margin-bottom: 5px;
}

/* Content */

.title-align-in-content {
  text-align: center;
  margin-top: 1.8em;
}

.subtitle-align-in-content {
  margin: 0.5em auto;
  text-align: center;
  font-weight: 500;
  letter-spacing: 0.01em;
}

/* Article */
.title-vertical-align {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: stretch;
}

.space-article {
  margin-bottom: 20px;
}

.space-article .circleMedium {
  right: -10%;
  top: -10%;
}

.space-article .circleBig {
  left: 40%;
  top: 30%;
}

.space-article .circleSmall {
  right: 5%;
  top: -10%;
}

#title-verical-align {
  color: #f15025;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: stretch;
  align-content: center;
  position: relative;
}

.link-more > a,
.categories p {
  color: #4a4a4a;
  font-size: 0.8em;
  text-transform: uppercase;
}

.link-more > a > i,
.categories i {
  color: #898989;
}

/* Brands */
.brand-tiles > h3 {
  margin: 30px auto;
}

.brand-tiles > .columns > .column {
  border: 3px solid #d8eff4;
  padding: 0.5rem;
  margin: 10px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.brand-tiles > .columns > .column:nth-child(2) {
  border: 3px solid #e7ecef;
}

.brand-tiles > .columns > .column img {
  opacity: 0.5;
}
.brand-tiles > .columns > .column img:hover {
  opacity: 1;
}
.brand-tiles > .columns > .column img {
  margin: 10px;
  max-width: 130px;
}
/* Footer */
.footer-right > p,
.footer-right > h2,
.footer-right > div {
  text-align: right;
  margin-top: 1.3rem;
  position: relative;
  z-index: 1;
}
.footer-right > button {
  margin-top: 1.3rem;
  float: right;
}

.footer-p {
  line-height: 1.8rem;
  font-size: 1rem;
}
.footer::before {
  margin-top: 13em;
}

#copyright-string {
  margin-top: 0.6rem;
  font-size: 0.8em;
}

.footer-items img {
  vertical-align: middle;
  margin-right: 10px;
  max-width: 66px;
  max-height: 66px;
}

.footer-items li a {
  color: #242424;
  font-size: 1.12em;
  display: flex;
  align-items: center;
}

.footer-items li {
  margin: 24px auto;
}

.footer-copyright {
  text-align: center;
  margin-bottom: 25px;
}

/* Breadcrumb */
.bd-breadcrumb {
  margin: 1.5em 0;
}
.breadcrumb,
.breadcrumb a,
.breadcrumb span {
  color: #898989;
}

.breadcrumb span {
  display: flex;
  justify-content: center;
  padding: 0 0.75em;
}

/* Categories */

.categories a > div {
  background-color: #e7ecef;
  padding: 0.75rem;
  position: relative;
  overflow: hidden;
}

.categories a > div:hover {
  background-color: #d8eff4;
}

.categories h2 {
  text-align: left;
}

.categories img {
  float: right;
  z-index: 1;
  position: relative;
}

.categories p {
  clear: both;
}

.categories-tiles .column > div {
  background-color: #e7ecef;
  padding: 0.75rem;
  height: 100%;
}
.categories-tiles .column > div:hover {
  background-color: #d8eff4;
}

.categories-tiles .column a > div {
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}
.series,
.products {
  margin: 20px 20px;
}
/* Series */
.series-text,
.series-text-left,
.series-text-left p,
.series-text p {
  text-align: center;
  line-height: 40px;
}

.series-text-left,
.series-text-left h2,
.series-text-left p {
  text-align: left;
}

.choose-brand > button.active {
  background-color: #d8eff4;
}

.choose-brand > button:active {
  box-shadow: none;
}
.choose-brand {
  margin: 15px 10px 5px 10px;
}

.choose-brand > button {
  height: 47px;
  min-width: 130px;
  border: none;
  border-radius: 34px;
  background-color: #e7ecef;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  margin-right: 10px;
  margin-bottom: 5px;
  color: #242424;
  font-size: 1.25em;
  cursor: pointer;
}
.choose-brand button > img {
  vertical-align: middle;
}

.grid-row {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
}

.grid-item {
  flex-basis: 20%;
  -ms-flex: auto;
  width: 259px;
  position: relative;
  padding: 10px;
  box-sizing: border-box;
}

.wrapping-link {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: 10px 6px 6px;
  z-index: 2;
}
.grid-item-wrapper img {
  width: 100%;
}
.grid-item-wrapper {
  -webkit-box-sizing: initial;
  -moz-box-sizing: initial;
  box-sizing: initial;
  margin: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  -webkit-transition: padding 0.15s cubic-bezier(0.4, 0, 0.2, 1), margin 0.15s cubic-bezier(0.4, 0, 0.2, 1), box-shadow 0.15s cubic-bezier(0.4, 0, 0.2, 1);
  transition: padding 0.15s cubic-bezier(0.4, 0, 0.2, 1), margin 0.15s cubic-bezier(0.4, 0, 0.2, 1), box-shadow 0.15s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
  text-align: center;
}

.CategorySquares .grid-item-container {
  padding-bottom: 20px;
}

.grid-item-content {
  background-color: #e7ecef;
  margin-top: 10px;
  padding: 7px;
}

.grid-item-wrapper .grid-item-content {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.grid-item-content.nobg {
  background-color: transparent;
}

.grid-item-wrapper-border {
  -webkit-box-sizing: initial;
  -moz-box-sizing: initial;
  box-sizing: initial;
  border: 3px solid #e7ecef;
  margin: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  -webkit-transition: padding 0.15s cubic-bezier(0.4, 0, 0.2, 1), margin 0.15s cubic-bezier(0.4, 0, 0.2, 1), box-shadow 0.15s cubic-bezier(0.4, 0, 0.2, 1);
  transition: padding 0.15s cubic-bezier(0.4, 0, 0.2, 1), margin 0.15s cubic-bezier(0.4, 0, 0.2, 1), box-shadow 0.15s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
  text-align: center;
}

/* One-series */
.one-series h2,
.one-series p,
.product-dscr p:last-child {
  text-align: left;
}
.one-series p {
  line-height: 40px;
  text-align: justify;
  text-justify: inter-word;
}
.one-series img {
  float: right;
}

/* Filters */
#filter-collapse-button {
  position: relative;
  z-index: 3;
}
#filter-collapse-button select {
  background-color: #e7ecef;
  border: none;
  font-size: 1em;
  border-radius: 0 !important;
  color: #4a4a4a;
  min-height: 30px;
}

.product-dscr select {
  background-color: #e7ecef;
  border: none;
  font-size: 1em;
  border-radius: 0 !important;
  color: #4a4a4a;
  min-height: 30px;
}

.product-dscr select {
  width: 180px;
}

.filter {
  position: relative;
  z-index: 1;
}

.bf2 {
  display: none;
}

.burgerFilter.is-active .bf1 {
  display: none;
}
.burgerFilter.is-active .bf2 {
  display: block;
}

a.burgerFilter.is-active {
  background-color: transparent;
}

.bf1 {
  color: #033a44;
}

@media screen and (min-width: 1024px) {
  .burgerFilter {
    display: none;
  }
}

.filter .burgerFilter i {
  float: right;
  height: 40px;
  width: 40px;
  background-color: #d8eff4;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.filter i:hover {
  background-color: #f15025;
  cursor: pointer;
  -webkit-transition: background-color 0.5s ease-out;
  -moz-transition: background-color 0.5s ease-out;
  -o-transition: background-color 0.5s ease-out;
  transition: background-color 0.5s ease-out;
}

.filter .burgerFilter {
  position: relative;
  z-index: 4;
  height: 40px;
  line-height: 40px;
  width: 90px;
  background-color: #e7ecef;
  border-radius: 0 40px 40px 0;
  text-align: middle;
  justify-content: center;
  float: left;
  padding-left: 10px;
}

.burgerFilter.is-active {
  width: 40px;
}

.filter .navbar {
  justify-content: center;
  align-items: center;
  height: 40px;
}

@media screen and (min-width: 1024px) {
  .navbar-item.select {
    align-items: stretch;
  }
}

.filter-wrapper {
  position: relative;
  z-index: 3;
  margin-top: 10px;
}
/* Products */
.products {
  text-align: center;
}

.products .grid-item {
  border: 3px solid #e7ecef;
}
p.is-hidden-tablet {
  margin: 20px 0;
  line-height: 1.8em;
  font-size: 1.12em;
}
.spec-wrapper {
  background-color: #f5f5f5;
  padding: 20px;
  width: 100%;
  margin-bottom: 0px;
  margin-top: 30px;
}
.gallery-wrapper {
  background-color: #fff;
  width: 100%;
}

.spec-wrapper .column div {
  margin: 20px auto;
}
.spec-wrapper i {
  color: #f15025;
  font-size: 1.8em;
}
.spliter {
  border-left: 1px solid #898989;
  height: 30px;
}
/* Product page*/
.slide {
  position: relative;
}
.is-8 > div {
  position: relative;
}

.product-dscr article div {
  display: flex;

  align-items: center;
}

.product-dscr label {
  font-weight: bold;
}
.product-dscr p {
  clear: both;
  text-align: left;
  margin: 15px 0;
  line-height: 2rem;
  font-size: 1.12rem;
}

p.product-details {
  clear: both;
  margin: 15px 0;
  line-height: 2rem;
  font-size: 1.12rem;
}

.product-dscr .columns {
  text-align: left;
  flex-direction: column;
}

.product-dscr .column {
  padding: 0.4rem;
}

.product-details ul {
  list-style: none;
  list-style-position: inside;
}

.product-details ul li::before {
  content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: #f15025; /* Change the color */
  font-weight: bold; /* If you want it to be bold */
  display: inline-block; /* Needed to add space between the bullet and the text */
  width: 1em; /* Also needed for space (tweak if needed) */
}

.product-dscr h2 {
  text-align: left;
  display: block;
  line-height: 40px;
}

.dot {
  background-color: #f15025;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  display: inline-block;
  margin-left: 10px;
  vertical-align: middle;
  margin-bottom: 5px;
}

#reset {
  line-height: 40px;
  margin: 5px;
  text-decoration: underline;
  cursor: pointer;
  margin: 0.5rem 0;
  font-size: 1em;
  padding: 0.2em 0.6em;
}

.certificate-logos img {
  height: 50px;
  margin: 10px 10px;
  margin-top: 20px;
}
/* Circles */

.circle {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 50%;
}
.circle-solid {
  position: absolute;
  background-color: #fff;
  border-radius: 50%;
}
.circleBig {
  width: 20vw;
  height: 20vw;
  left: 30%;
}
.circleExtraSmall {
  width: 10vw;
  height: 10vw;
}

.circleSmall {
  width: 10vw;
  height: 10vw;
}
.circleMedium {
  width: 12vw;
  height: 12vw;
}
.circleExtraBig {
  width: 26vw;
  height: 26vw;
}

.circleblue {
  background-color: rgba(216, 239, 244, 0.8);
  width: 3vw;
  height: 3vw;
  top: 40%;
  z-index: -1;
}

.circlegray {
  position: absolute;
  background-color: rgba(231, 236, 239, 0.6);
  width: 5vw;
  height: 5vw;
  top: 50%;
  z-index: -1;
}

.hero.bluebgcolor.is-small .circleMedium {
  left: 80%;
  top: -10%;
}
.hero.bluebgcolor.is-small .circleExtraSmall {
  right: 35%;
  bottom: -10%;
}
.hero.bluebgcolor.hero-title-center .circleSmall {
  left: 55%;
  top: -60%;
}
footer.hero.graybgcolor {
  overflow: hidden;
  margin-top: 40px;
  position: relative;
}
footer.hero.graybgcolor .circleBig {
  left: 80%;
  top: -5%;
}
footer.hero.graybgcolor .circleMedium {
  left: 70%;
  top: -5%;
}
footer.hero.graybgcolor .circleSmall {
  right: 5%;
  top: 40%;
}
footer.hero.graybgcolor .circleExtraBig {
  right: -25%;
  top: 25%;
}

/* About */
.about h2 {
  margin-bottom: 20px;
  text-align: left;
}

.coworkers .columns {
  margin: 20px 0;
}
.coworkers .columns.is-vcentered {
  align-items: flex-start;
}
.coworkers h3,
.coworkers h6 {
  text-align: left;
  margin: 10px 0;
}
.coworkers p {
  margin: 20px 0;
  line-height: 40px;
}
/* Contact */
.contact > .column {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.contact > .is-7 > div {
  padding: 20px;
  width: 100%;
  background-color: #d8eff4;
}

.contact > .is-7 > div > div {
  display: flex;
  align-items: center;
}
.contact > .is-5 > div {
  padding: 20px;
  width: 100%;
  background-color: #e7ecef;
  display: flex;
  justify-content: center;
  align-items: center;
}
.is-5 table {
  border-collapse: separate;
  border-spacing: 10px 0;
}
.is-5 td {
  padding: 10px 0;
}

.is-5 table tr > td:first-child {
  color: #033a44;
  font-size: 1.12em;
  font-weight: bold;
}

.btn-slogan {
  margin: 20px 0;
}
.btn-slogan h4 {
  text-align: right;
  font-weight: bold;
}
.btn-slogan .is-5 {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.contact i {
  border-radius: 50%;
  padding: 0.7em;
  height: 60px;
  width: 60px;
  text-align: center;
  vertical-align: middle;
  font-size: 1.2em;
  color: #033a44;
  background-color: #fff;
  line-height: 33px;
  margin: 25px 0;
}
.contact span:last-child {
  color: #033a44;
  font-size: 1.25em;
  font-weight: bold;
  margin-left: 15px;
  text-align: left;
}
/* Map in Contact*/

.googlemap {
  overflow: hidden;
  padding-bottom: 365px;
  position: relative;
  height: 0;
  margin-top: 40px;
}

.googlemap iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.hero.bluebgcolor {
  overflow: hidden;
  position: relative;
}
.hero.bluebgcolor h2 {
  z-index: 1;
  position: relative;
}

.hero-title-center .hero-body {
  padding: 1rem;
}
/* Ask a question form */
.modal {
  display: none; /* Hidden by default */
  z-index: 3; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.7); /* Black w/ opacity */
}
.modal .modal-background {
  background-color: transparent;
}

.modal.active {
  display: block;
}

/* Modal Content */
.modal-content .control {
  max-width: 90%;
  margin: 0 auto;
}

.modal-content form > button {
  margin: 30px 0;
}

.modal.search .modal-content {
  padding: 0;
}

.search input {
  border: none;
}

.textarea {
  height: 130px;
  margin: auto;
  border: 1px solid #898989;
  border-radius: 0;
  color: #242424;
}

.modal-content form input {
  margin-bottom: 20px;
  border-radius: 0;
  border: 1px solid #898989;
}

.modal-content form {
  margin-top: 10%;
}
.search-modal {
  margin: auto;
  width: 100%;
  text-align: center;
}

.success-form p:first-child,
.success-form h2 {
  text-align: center;
  margin-top: 40px;
}
.success-form .navbar-item {
  margin: auto;
  margin-top: 40px;
}

.success-form p:last-child {
  margin-top: 40px;
  text-align: left;
}

.search-modal > input {
  width: 100%;
  height: 40px;
  border: 1px solid #898989;
  font-size: 1em;
  line-height: 22px;
  padding: 0 10px;
}
.field .input,
.field .textarea {
  box-shadow: none;
}
.modal-content {
  background-color: #fff;
  margin: auto;
  padding: 20px;
  width: 500px;
  text-align: center;
}

.close {
  color: #898989;
  position: absolute;
  font-size: 25px;
  left: 0;
  right: auto;
  margin-left: 20px;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

/* Carousel */
.mainContent .carousel {
  margin-top: 30px;
}
.carousel-title {
  margin-top: 25px;
}

.carousel .wrapper-carousel-img > a > div {
}
.carousel .wrapper-carousel-img > a > div > img {
  flex-direction: column;
}
.carousel .slick-slide > div {
  margin: 0 10px;
}
.carousel .wrapper-carousel-img {
  margin: 0px;
  padding: 10px;
  background-color: #fff;
  border: 2px solid #d8eff4;
  text-align: center;
  display: block;
  overflow: hidden;
}

.responsive-carousel {
  overflow: hidden;
}
.responsive-carousel .wrapper-carousel-img > a > div {
  margin: 0 10px;
  padding: 10px;
  background-color: #fff;
  border: 2px solid #d8eff4;
  text-align: center;
  display: block;
  overflow: hidden;
}

.responsive-carousel .grid-item-content {
  color: #242424;
}

.responsive-carousel ul.slick-dots {
  position: relative;
  display: flex !important;
  justify-content: center;
  margin: 20px 0 0;
}
.responsive-carousel {
  margin-top: 25px;
}
.responsive-carousel div.wrapper-carousel-img > a div {
  height: 100%;
  display: block;
}

.slick-track {
  display: flex;
  width: 100%;
}
.product-show {
  max-width: 100%;
  left: 0;
  top: 0;
  overflow: hidden;
  border: 3px solid #d8eff4;
  text-align: center;
  vertical-align: middle;
}

.slick-dots li {
  display: inline-block;
  margin-bottom: 15px;
}

.carouselWrapper {
  width: 100vw;
  overflow: hidden;
}
.wrapper-carousel-img {
  flex-shrink: 0;
  height: 100%;
}
.slick-slide > div {
  height: 100%;
}

.main-show {
  max-width: 100%;
  left: 0;
  top: 0;
  overflow: hidden;
  text-align: center;
  vertical-align: middle;
  z-index: 100;
}
.main-show button.slick-prev,
.main-show button.slick-next,
.responsive-carousel button.slick-prev,
.responsive-carousel button.slick-next {
  display: none !important;
}

.single-item.main-show ul.slick-dots {
  text-align: left;
  margin-left: 10px;
  margin-top: -30px;
  padding-top: 0;
  padding-bottom: 0;
}

.single-item.main-show ul.slick-dots li {
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.main-show ul > li::after {
  float: left;
}

.slick-slider.carousel {
  text-align: center;
}

.product-show > ul > li {
  display: inline-block;
  margin-bottom: 5px;
}
.title-main-show {
  position: absolute;
  bottom: 7%;
}
.slick-slider {
  z-index: 101;
}

.slick-dots > li > button {
  visibility: hidden;
  width: 15px;
  display: block !important;
  margin: 0 7px;
  padding: 0;
}

.slick-dots > li > button::after {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #979797;
  position: relative;
  float: right;
  content: "";
  margin: 0 7px;
  visibility: visible !important;
  cursor: pointer;
}

.slick-dots > li.slick-active > button::after {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #f15025;
  position: relative;
  text-align: center;
  content: "";
  margin: 0 7px;
  visibility: visible !important;
}

.single-item button.slick-prev {
  background-image: url("chevron-left-solid.svg");
  background-repeat: no-repeat;
  border: none;
  position: absolute;
  height: 30px;
  width: 30px;
  background-position: 50% 50%;
  background-color: transparent;
  text-indent: -9000px;
  top: 50%;
  left: 0;
  z-index: 2;
  cursor: pointer;
}

.single-item button.slick-next {
  background-image: url("chevron-right-solid.svg");
  background-repeat: no-repeat;
  border: none;
  position: absolute;
  height: 30px;
  width: 30px;
  background-position: 50% 50%;
  background-color: transparent;
  text-indent: -9000px;
  top: 50%;
  right: 0;
  z-index: 2;
  cursor: pointer;
}

.carousel button.slick-prev {
  background-image: url("chevron-left-solid.svg");
  background-repeat: no-repeat;
  border: none;
  position: absolute;
  height: 20px;
  width: 20px;
  background-position: 50% 50%;
  text-indent: -9000px;
  background-color: transparent;
  top: 50%;
  left: -10px;
  z-index: 2;
  cursor: pointer;
}

.carousel button.slick-next {
  background-image: url("chevron-right-solid.svg");
  background-repeat: no-repeat;
  border: none;
  position: absolute;
  height: 20px;
  width: 20px;
  background-position: 50% 50%;
  text-indent: -9000px;
  background-color: transparent;
  top: 50%;
  right: -10px;
  z-index: 2;
  cursor: pointer;
}

.wrapping-link {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 2;
}

/* Product tabs */

.slTabs li {
  border-bottom-style: hidden;
  font-size: 1em;
  text-transform: uppercase;
  color: #033a44;
}
.slTabs .react-tabs__tab-panel {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: stretch;
}

.slTabs:not(.singleTab) li:hover .tabLabel,
.slTabs:not(.singleTab) li.react-tabs__tab--selected .tabLabel {
  color: #f15025;
  cursor: pointer;
}

.slTabs li:not(:last-child) .tabLabel {
  border-right: 1px solid #898989;
  padding-right: 20px;
}
@media screen and (max-width: 555px) {
  .slTabs li:not(:last-child) .tabLabel {
    padding-right: 10px;
  }
}
.tabsHr {
  border-bottom-color: #dbdbdb;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  margin-top: 0px;
  margin-bottom: 20px;
}

.slTabs > ul li {
  display: inline-block;
  padding: 5px 0;
}

.gallery-tabs ul.react-tabs__tab-list:first-child {
  position: absolute;
  width: 100%;
  z-index: 3;
}

.gallery-tabs ul.react-tabs__tab-list:first-child li {
  float: right;
  top: 0;
  width: 100px;
  height: 50px;
  border-bottom-right-radius: 100px;
  border-bottom-left-radius: 100px;
  border: none;
  font-size: 1em;
  text-align: center;
  padding-top: 10px;
  z-index: 3;
}

.gallery-tabs ul.react-tabs__tab-list:first-child li {
  background-color: #e7ecef;
  cursor: pointer;
}

.gallery-tabs ul.react-tabs__tab-list:first-child li.active,
.gallery-tabs ul.react-tabs__tab-list:first-child li:hover {
  background-color: #d8eff4;
}
.gallery-tabs ul.react-tabs__tab-list:first-child li a {
  color: #898989;
}
.gallery-tabs ul.react-tabs__tab-list:first-child li.active a,
.gallery-tabs ul.react-tabs__tab-list:first-child li:hover a {
  color: #033a44;
}

.slTabs > ul > li {
  margin: 10px 10px;
}

.slTabs > div > div {
  display: block;
  margin: 10px 20px 10px 0;
}

.slTabs > div > div > p {
  display: inline-block;
}
.slTabs > div > div i {
  font-size: 1.3em;
  margin-right: 10px;
}

.error-wrapper {
  background-color: #e7ecef;
  text-align: center;
  margin-bottom: 40px;
}
.error-wrapper h4 {
  font-size: 1.7em;
}

.error-wrapper button {
  margin-top: 30px;
}

.error-wrapper img {
  max-width: 730px;
  max-width: 60%;
}

/* Extra Large display */
@media (max-width: 1171px) {
  .choose-brand > button {
    min-width: 50px;
    height: 35px;
  }
  .brandwrapper {
    display: inline-block;
    line-height: 32px !important;
    font-size: 16px;
  }
}
@media (max-width: 1215px) {
  .brand-tiles > .columns > .column img {
    max-width: 110px;
  }
}

/* Large display */
@media (max-width: 1170px) and (min-width: 1024px) {
  .grid-item {
    flex-basis: 33.33%;
  }
}

/* Medium display */
@media (max-width: 1023px) and (min-width: 721px) {
  #aligncenter {
    padding: 1.5rem 0;
  }
  .grid-item {
    flex-basis: 33.33%;
  }
  .footer-items img {
    display: none;
  }
  #navbar-collapse-button .navbar-link::after {
    display: none;
  }
}

@media (max-width: 1023px) {
  #filter-collapse-button .navbar-item,
  #filter-collapse-button #reset {
    margin-left: 40px;
  }
}

@media (max-width: 900px) {
  .wrapper-slider-img .subtitle.is-3 {
    font-size: 1.3em;
  }
  .wrapper-slider-img .subtitle.is-5 {
    font-size: 1em;
  }

  #navbar-collapse-button .navbar-link::after {
    display: none;
  }
}
/*Small bulma mobile*/
@media (max-width: 768px) {
  .is-mobile-reversed {
    flex-direction: column-reverse;
    display: flex;
  }
  .footer-right > p,
  .footer-right > h2,
  .footer-right > div {
    text-align: center;
  }

  .columns.is-mobile > .column.is-5 {
    margin-top: 0.8rem !important;
    display: block;
    text-align: center;
    width: 100%;
  }

  .one-series div:last-child {
    display: none;
  }

  .brand-tiles > .columns > .column > div > div {
    display: inline-block;
  }

  .coworkers img {
    max-width: 250px;
  }
}

/* Extra small display */
@media (max-width: 720px) {
  .repairs div:last-child {
    display: none;
  }

  .button.is-primary.is-large {
    font-size: 1em;
  }

  #aligncenter {
    padding: 2rem 0;
  }

  .grid-item {
    flex-basis: 50%;
  }

  .footer-items img {
    display: none;
  }
  .modal-content {
    width: 100%;
  }
}
/* Super Small display */
@media (max-width: 555px) {
  .grid-item {
    flex-basis: 100%;
  }
  .repairs div:last-child {
    display: none;
  }

  .footer-items img {
    display: none;
  }

  .slTabs li:first-child a,
  .slTabs li a,
  .slTabs li a {
    font-size: 0.8em;
  }

  .slTabs > ul > li {
    margin: 0 5px;
  }
  .certificate-logos img {
    height: 40px;
    margin: 5px 5px;
  }
  .hero-body {
    padding: 1rem;
  }
}
header.container {
  margin: 0px auto 20px auto;
}
.footer-copyright {
  padding: 0 20px;
}

/*----------------------------MultiSelect------------------------*/
.multi-select-container {
  display: inline-block;
  position: relative;
}

.multi-select-menu {
  position: absolute;
  left: 0;
  top: 0.8em;
  z-index: 1;
  float: left;
  min-width: 100%;
  background: #fff;
  margin: 1em 0;
  border: 1px solid #aaa;
  display: none;
}

.multi-select-menuitem {
  display: block;
  font-size: 0.875em;
  padding: 0.6em 1em 0.6em 30px;
  white-space: nowrap;
  text-align: left;
}

.multi-select-menuitem--titled:before {
  display: block;
  font-weight: bold;
  content: attr(data-group-title);
  margin: 0 0 0.25em -20px;
}

.multi-select-menuitem--titledsr:before {
  display: block;
  font-weight: bold;
  content: attr(data-group-title);
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.multi-select-menuitem + .multi-select-menuitem {
  padding-top: 0;
}

.multi-select-presets {
  border-bottom: 1px solid #ddd;
}

.multi-select-menuitem > input {
  position: absolute;
  margin-top: 0.25em;
  margin-left: -20px;
}

.multi-select-button {
  display: inline-block;
  font-size: 1em;
  padding: 0.2em 0.6em;
  max-width: 16em;
  min-width: 8em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: -0.5em;
  background-color: #e7ecef;
  cursor: default;
}

.multi-select-button:after {
  content: "";
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0.4em 0.4em 0 0.4em;
  border-color: #999 transparent transparent transparent;
  margin-left: 0.4em;
  vertical-align: 0.1em;
}

.multi-select-container--open .multi-select-menu {
  display: block;
}

.multi-select-container--open .multi-select-button:after {
  border-width: 0 0.4em 0.4em 0.4em;
  border-color: transparent transparent #999 transparent;
}

.multi-select-container--positioned .multi-select-menu {
  /* Avoid border/padding on menu messing with JavaScript width calculation */
  box-sizing: border-box;
}

.multi-select-container--positioned .multi-select-menu label {
  /* Allow labels to line wrap when menu is artificially narrowed */
  white-space: normal;
}
/*----------------------------MultiSelect------------------------*/

#product-filter .multi-select-menuitems label {
  text-align: left;
}

/*custom filter checkboxes*/
.multi-select-menuitem {
  display: block;
  margin-bottom: 15px;
}

.multi-select-menuitem input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.multi-select-menuitem label {
  position: relative;
  cursor: pointer;
}

.multi-select-menuitem label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid #aaa;
  padding: 9px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
}

.multi-select-menuitem input:checked + label:before {
  content: "";
  background: #f15025;
  border: 1px solid #f15025;
}

.navbar-item img {
  max-height: 90px;
}

.navbar-placeholder {
  cursor: default;
}

.navbar-menu.is-active span.navbar-link:hover {
  background: none;
}

div.hero-body {
  display: block !important;
}

.fileLink {
  color: #033a44;
}

.gal {
  border: 3px solid #d8eff4;
  min-width: 600px;
  text-align: center;
}

.indicators {
  position: absolute;
  bottom: 10px;
  width: 100%;
}

.react-slideshow-container .nav span {
  border: none;
}

.react-slideshow-container .nav {
  border-radius: 0 !important;
  background-color: transparent !important;
}

.react-slideshow-container .nav:first-of-type span {
  background-image: url("chevron-left-solid.svg");
  background-repeat: no-repeat;
  border: none;
  position: absolute;
  height: 30px;
  width: 30px;
  background-position: 50% 50%;
  background-color: transparent;
  text-indent: -9000px;
  top: 50%;
  left: 0;
  z-index: 2;
  cursor: pointer;
  transform: none;
  border-radius: 0;
  background-color: transparent;
}

.react-slideshow-container .nav:last-of-type span {
  background-image: url("chevron-right-solid.svg");
  background-repeat: no-repeat;
  border: none;
  position: absolute;
  height: 30px;
  width: 30px;
  background-position: 50% 50%;
  background-color: transparent;
  text-indent: -9000px;
  top: 50%;
  right: 0;
  z-index: 2;
  cursor: pointer;
  transform: none;
  border-radius: 0;
  background-color: transparent;
}

.galleryTabs .react-tabs__tab-list li {
  float: right;
  top: 0;
  width: 100px;
  height: 50px;
  border-bottom-right-radius: 100px;
  border-bottom-left-radius: 100px;
  border: none;
  font-size: 1em;
  text-align: center;
  padding-top: 10px;
  z-index: 3;
  background-color: #e7ecef;
  cursor: pointer;
  color: #898989;
}

.galleryTabs .react-tabs__tab-list li.react-tabs__tab--selected,
.galleryTabs .react-tabs__tab-list li:hover {
  background: #d8eff4;
  color: #033a44;
}

.galleryTabs .react-tabs__tab-list {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}

.gallery-wrapper .react-slideshow-container + div.indicators > div,
.gallery-wrapper .react-slideshow-container + div.indicators > div.active {
  background-color: #f15025;
}

.recommendedWrapper {
  margin-bottom: 0px;
  margin-top: 30px;
}

.recommendedWrapper h3 {
  margin-bottom: 20px;
}

#product-fields P {
  margin: 0;
}

.titleWrap {
  margin-bottom: 15px;
}

.titleWrap article {
  margin-bottom: 10px;
}

.fcontainer {
  margin-top: 15px;
}

.mainContent img {
  max-width: 100%;
  max-height: 100%;
}

.grid-item-container.product-list-item img {
  margin-top: 10px;
}
.grid-item-container.product-list-item .fas,
.grid-item-container.category-list-item .fas,
.carouselItem .fas {
  color: #e7ecef;
}
.categories-tiles .fas {
  color: #bbb;
}

.catBlock {
  min-height: 200px;
}

.columns.contact a {
  color: #033a44;
}

.articletext {
  line-height: 2.5em;
  font-family: "Open Sans", sans-serif;
  font-size: 1.25em;
  margin-bottom: 30px;
  text-align: justify;
  text-justify: inter-word;
}

.articletext p:not(:first-child) {
  margin-top: 30px;
}

.is-reversed {
  flex-direction: row-reverse;
}
.single-item.product-show .slick-list {
  margin-bottom: -6px;
}
.slick-slider.carousel {
  overflow: hidden;
}
.slick-slider.carousel img {
  height: auto;
  width: auto;
}

.circless {
  z-index: 0;
}
.mainslider {
  position: relative;
  z-index: 1;
}

.input.input:focus,
.modal.form input:focus,
.modal.form textarea:focus {
  box-shadow: none;
  border-color: #fa7c91 !important;
}

.modal.form div.required input,
.modal.form div.required textarea {
  box-shadow: none;
  border-color: #cf0000 !important;
}

.product-list-item .imgwrapper .img-loading {
  height: 172px;
}

.grid-item-container.category-list-item .imgwrapper {
  padding: 25px;
  position: relative;
}
.grid-item-container.category-list-item .imgwrapper .img-loading {
  width: 250px;
  height: 250px;
}

.searchWrapper i {
  color: #999;
}

.brandwrapper {
  display: inline-block;
  line-height: 43px;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.brandwrapper img {
  max-height: 100%;
  max-width: 100%;
}

.navbar-item .multi-select {
  min-width: 150px;
}
.navbar-item .multi-select:not(:last-child) {
  margin-right: 20px;
}
#filter-collapse-button.is-active .navbar-item .multi-select {
  margin-right: 20px;
  margin-bottom: 10px;
}
#filter-collapse-button.is-active #reset {
  margin-top: -10px;
}

.multi-select .dropdown-container {
  /* #033A44 */
  outline: none;
  box-shadow: none;
  border: none;
  background: #e7ecef;
  border-radius: 0;
}
.dropdown-heading {
  border-radius: 0;
}
.dropdown-content {
  background-color: transparent;
  box-shadow: none;
}

@keyframes fadeInImg {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.img-loading {
  opacity: 0;
}
.img-loaded:not(.animated) {
  animation: fadeIn;
  animation-duration: 0.5s;
}

.mainslider .img-loaded {
  z-index: 0;
}

.sliderWrapper {
  margin-top: -20px;
}

@media screen and (min-width: 1408px) {
  .imgSlide {
    min-height: 526px;
  }
}

@media screen and (max-width: 1407px) and (min-width: 1216px) {
  .imgSlide {
    min-height: 466px;
  }
}

@media screen and (max-width: 1215px) and (min-width: 769px) {
  .imgSlide {
    min-height: 330px;
  }
}

.prodWrapper .grid-item-wrapper-border {
  min-height: 230px;
}

.product-show.preloading {
  height: 500px;
}

.catBlock .img-loading {
  height: 221px;
}

.categories-tiles.columns > div.column > div {
  overflow: hidden;
}

/* .catBlock .img-loaded, */
.categories-tiles.columns > div.column > div .img-loaded,
.container.columns.one-series .img-loaded {
  animation: fadeInDown;
  animation-duration: 0.5s;
}

.preloader-wrapper {
  margin: 0px;
  background: radial-gradient(#003452, #111);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.preloader-container {
  width: 200px;
  height: 200px;
  border-radius: 100%;
  background: #003452;
  position: relative;
}

.preloader-elm:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 100%;
  border-bottom: 0 solid #ffffff05;
  box-shadow: -12px 0 9px 0px #c43b34, 0px -12px 9px 0px #5ea947, 0px 9px 9px 0px #7a71a8, 9px 0px 9px 0px #efdb2f;
  filter: blur(1px);
  animation: 2s preloader-rt linear infinite;
}

.preloader-elm:after {
  font-size: 25px;
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  color: #fff;
  width: 100%;
  height: 100%;
  position: absolute;
  text-align: center;
  line-height: 350px;
}

@keyframes preloader-rt {
  100% {
    transform: rotate(360deg);
  }
}

.creds {
  font-size: 0.8em;
  bottom: 3px;
  left: 5px;
  position: absolute;
  color: #777;
}

.creds a {
  color: #555;
}

.creds a:hover {
  color: #f15025;
}

.dropdown-container {
  position: inherit !important;
}

.dropdown-content {
  width: auto !important;
  min-width: 150px;
  margin-top: -10px;
}

.select-panel .select-item > div {
  white-space: nowrap;
}

@media screen and (max-width: 525px) {
  .single-item.product-show .img {
    min-height: 350px !important;
  }
}
